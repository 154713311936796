import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from 'components/Layout'
import { LinkWithText } from 'components/Link'
import { H5, Text } from 'components/Typography'
import { ROUTE } from 'constants/routes'
import { FONT_STACK, SPACE, FONT_SIZE, COLOR_INTENT, FONT_WEIGHT } from 'Theme'
import { NewsletterSubscribeForm } from './NewsletterSubscribeForm'

const StyledLinkWithText = styled(LinkWithText)`
  text-align: center;
  text-decoration: underline;
  color: ${COLOR_INTENT.FOOTER.NEWSLETTER_SUBSCRIBE.TEXT};
`

export const NewsletterSubscribe = () => (
  <Flex flexDirection="column">
    <H5
      color={COLOR_INTENT.FOOTER.NEWSLETTER_SUBSCRIBE.HEADING}
      fontFamily={FONT_STACK.SANS}
      mb={SPACE.PX_16}
    >
      Stay in the loop
    </H5>
    <Box width="100%" mb={SPACE.PX_10}>
      <NewsletterSubscribeForm />
    </Box>
    <Text
      fontSize={{ DESKTOP: FONT_SIZE.PX_12, MOBILE: FONT_SIZE.PX_10 }}
      fontFamily={FONT_STACK.SANS}
      color={COLOR_INTENT.FOOTER.NEWSLETTER_SUBSCRIBE.TEXT}
    >
      By clicking &ldquo;Subscribe&rdquo; you&apos;re agreeing to{' '}
      <StyledLinkWithText
        fontWeight={FONT_WEIGHT.NORMAL}
        fontSize={{ DESKTOP: FONT_SIZE.PX_12, MOBILE: FONT_SIZE.PX_10 }}
        href={ROUTE.TERMS_OF_USE()}
      >
        receive emails from The Expert.
      </StyledLinkWithText>
    </Text>
  </Flex>
)
