import React from 'react'
import Icon, { ICON_SIZE } from 'components/Icon'
import { Box, Container, Flex } from 'components/Layout'
import { type NavigationItem } from 'routes/utils/formatNavMenu'
import { COLOR_INTENT, SPACE } from 'Theme'
import { useMedia } from 'useMedia'
import { CategoriesNavigationItem } from './CategoriesNavigationItem'

const getMainCategoriesCount = ({
  isDesktop,
  isTablet,
}: {
  isDesktop: boolean
  isTablet: boolean
}) => {
  if (isTablet) {
    return 5
  }
  if (isDesktop) {
    return 6
  }
  return 8
}

interface CategoriesNavigationProps {
  categoriesNavigation: NavigationItem[]
}

export const CategoriesNavigation: React.FC<
  React.PWC<CategoriesNavigationProps>
> = ({ categoriesNavigation }) => {
  const { DESKTOP: isDesktop, TABLET: isTablet } = useMedia()
  const mainCategoriesCount = getMainCategoriesCount({ isDesktop, isTablet })
  const mainCategories = categoriesNavigation.slice(0, mainCategoriesCount)
  const restCategories = categoriesNavigation.slice(mainCategoriesCount)

  return (
    <Box>
      <Container>
        <Flex gap={SPACE.PX_30} justifyContent="center">
          {mainCategories.map((navigationItem: any, index) => (
            <CategoriesNavigationItem
              key={index}
              navigationItem={navigationItem}
            />
          ))}
          {restCategories.length > 0 && (
            <CategoriesNavigationItem
              navigationItem={{
                title: 'More',
                path: '#',
                children: restCategories,
              }}
              icon={
                <Icon.ChevronDownSmall
                  size={ICON_SIZE.PX_18}
                  color={COLOR_INTENT.GRAY_70}
                />
              }
            />
          )}
        </Flex>
      </Container>
    </Box>
  )
}
