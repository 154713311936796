import { HoverCard } from '@mantine/core'
import React, { type ReactNode } from 'react'
import styled from 'styled-components'
import { Box, Flex } from 'components/Layout'
import { LinkWithText } from 'components/Link'
import { type NavigationItem } from 'routes/utils/formatNavMenu'
import { COLOR_INTENT, FONT_SIZE, FONT_WEIGHT, SPACE } from 'Theme'

interface CategoriesNavigationItemProps {
  navigationItem: NavigationItem
  icon?: ReactNode
}

const CategoriesNavigationLink = styled(LinkWithText)`
  position: relative;
  text-decoration: none;
  color: ${COLOR_INTENT.GRAY_90};
  transition: width 0.3s ease;
  letter-spacing: 0.06rem;
  &:hover {
    color: ${COLOR_INTENT.MENU.TEXT_LINK.HOVER};
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 0;
    height: 1px;
    background-color: ${COLOR_INTENT.MENU.TEXT_LINK.HOVER};
    transition: width 0.3s ease;
  }

  &:hover::after,
  &:active::after {
    width: 100%;
  }
`

const SubCategoriesNavigationLink = styled<{ fontWeight?: string }>(
  LinkWithText
)<{ fontWeight?: string }>`
  text-decoration: none;
  color: ${COLOR_INTENT.GRAY_90};
  transition: width 0.3s ease;
  font-size: ${FONT_SIZE.PX_13};
  font-weight: ${({ fontWeight }) => fontWeight ?? FONT_WEIGHT.MEDIUM};
  line-height: ${SPACE.PX_18};
  &:hover {
    color: ${COLOR_INTENT.MENU.TEXT_LINK.HOVER};
  }
`

const CategoriesDropdownWrapper = styled(Flex)`
  row-gap: ${SPACE.PX_12};
  column-gap: ${SPACE.PX_50};
`

export const CategoriesNavigationItem: React.FC<
  React.PWC<CategoriesNavigationItemProps>
> = ({ navigationItem, icon }) => {
  const { title, path, children } = navigationItem
  const hasSomeCategorySubItems = children.some(
    (subChildren) => subChildren.children.length > 0
  )

  return (
    <Box py={SPACE.PX_12}>
      <HoverCard shadow="none" position="bottom-start">
        <HoverCard.Target>
          <Box>
            <Flex alignItems="flex-start" gap={SPACE.PX_4}>
              <CategoriesNavigationLink
                textDecoration="underline"
                textTransform="uppercase"
                fontSize={FONT_SIZE.PX_13}
                href={path}
              >
                {title}
              </CategoriesNavigationLink>
              {icon && icon}
            </Flex>
          </Box>
        </HoverCard.Target>
        {children.length > 0 && (
          <HoverCard.Dropdown p={0}>
            <Box
              maxWidth="800px"
              py={SPACE.PX_18}
              px={SPACE.PX_20}
              backgroundColor={COLOR_INTENT.MENU.BACKGROUND}
            >
              <CategoriesDropdownWrapper
                flexDirection={hasSomeCategorySubItems ? 'row' : 'column'}
                pr={SPACE.PX_30}
              >
                {children.map((child, index) => (
                  <Box key={index}>
                    <SubCategoriesNavigationLink href={child.path}>
                      {child.title}
                    </SubCategoriesNavigationLink>
                    {child.children.length > 0 && (
                      <CategoriesDropdownWrapper
                        flexDirection="column"
                        mt={SPACE.PX_12}
                      >
                        {child.children.map((subChild, subIndex) => (
                          <SubCategoriesNavigationLink
                            href={subChild.path}
                            key={subIndex}
                            fontWeight={FONT_WEIGHT.NORMAL}
                          >
                            {subChild.title}
                          </SubCategoriesNavigationLink>
                        ))}
                      </CategoriesDropdownWrapper>
                    )}
                  </Box>
                ))}
              </CategoriesDropdownWrapper>
            </Box>
          </HoverCard.Dropdown>
        )}
      </HoverCard>
    </Box>
  )
}
